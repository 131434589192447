import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  HiHome,
  HiUser,
  HiMiniPhone,
  HiOutlineArrowTopRightOnSquare,
  HiBuildingOffice2,
} from "react-icons/hi2";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaEnvelope,
  FaPhone,
} from "react-icons/fa";
import { SiOpenproject } from "react-icons/si";
import { GrServices } from "react-icons/gr";
import { HiMenu, HiX } from "react-icons/hi";

import digilogo from "../../assets/digilogo.png";

const NavItem = ({ icon, text, link, onClick }) => {
  const Icon = icon;
  return (
    <div
      className="text-slate-800 hover:text-blue-600 min-w-max ml-5 flex items-center space-x-2 text-lg"
      onClick={onClick}
    >
      <Icon className="" />
      <NavLink to={link}>{text}</NavLink>
    </div>
  );
};

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {  
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav className="bg-black text-white p-2 flex justify-between items-center border-b-4">
        <div className="flex space-x-4">
          <a
            href="mailto:someone@example.com"
            className="p-2 rounded flex items-center space-x-1"
          >
            <FaEnvelope className="text-white" />
            <span>someone@example.com</span>
          </a>
          <a
            href="tel:+7488282373"
            className="p-2 rounded flex items-center space-x-1 "
          >
            <FaPhone className="text-white" />
            <span>+7488282373</span>
          </a>
        </div>
        <div className="flex space-x-4">
          <a
            href="https://facebook.com"
            className="hover:bg-lime-600 p-2 rounded border border-white"
          >
            <FaFacebookF className="text-white" />
          </a>
          <a
            href="https://instagram.com"
            className="hover:bg-lime-600 p-2 rounded border border-white"
          >
            <FaInstagram className="text-white" />
          </a>
          <a
            href="https://twitter.com"
            className="hover:bg-lime-600 p-2 rounded border border-white"
          >
            <FaTwitter className="text-white" />
          </a>
          <a
            href="https://youtube.com"
            className="hover:bg-lime-600 p-2 rounded border border-white"
          >
            <FaYoutube className="text-white" />
          </a>
        </div>
      </nav>

      <nav className="bg-white w-full flex items-center justify-between pb-3 border-b-2 border-gray-200 z-50">
        <div className="flex items-center ml-14 mt-4">
          <NavLink to="/" className="flex items-center ml-5 mr-5">
            <img
              src={digilogo}
              alt="logo"
              className="inline w-24 md:w-30 transition-transform transform-gpu hover:blur-0 mr-2"
            />
            <div className="flex flex-col gap-0">
              <span className="text-2xl -ml-4 font-bold text-blue-950">
                DigiSoulTech
              </span>
              <span className="text-xs ml-1">Complete IT Solution</span>
            </div>
          </NavLink>
        </div>
        <div className="md:hidden flex items-center mr-5">
          <button onClick={toggleMenu} className="text-6xl focus:outline-none">
            {isOpen ? <HiX /> : <HiMenu />}
          </button>
        </div>
        <div
          className={`md:flex items-center space-x-5 mr-40 mt-5 ${
            isOpen ? "block" : "hidden"
          } md:block`}
        >
          <NavItem icon={HiHome} text="Home" link="/" onClick={closeMenu} />
          <div className="relative group">
            <div className="flex items-center space-x-6 hover:text-blue-600 cursor-pointer">
              <HiBuildingOffice2 />
              <span>Company</span>
            </div>
            <div className="absolute left-0 hidden group-hover:block bg-white shadow-xl rounded-xl p-5 z-50 transition-transform transform-gpu duration-300 ease-in-out">
              <div className="flex flex-col gap-6">
                <NavItem
                  icon={HiUser}
                  text="About Us"
                  link="/aboutus"
                  onClick={closeMenu}
                />
                <NavItem
                  icon={HiOutlineArrowTopRightOnSquare}
                  text="Career"
                  link="/career"
                  onClick={closeMenu}
                />
                <NavItem
                  icon={HiMiniPhone}
                  text="Contact Us"
                  link="/contactus"
                  onClick={closeMenu}
                />
                <NavItem
                  icon={HiOutlineArrowTopRightOnSquare}
                  text="Blog"
                  link="/blog"
                  onClick={closeMenu}
                />
              </div>
            </div>
          </div>
          <div className="relative group">
            <div className="flex items-center space-x-2 hover:text-blue-600 cursor-pointer">
              <GrServices />
              <span>Services</span>
            </div>
            <div className="absolute left-0 hidden group-hover:block bg-white shadow-xl rounded-xl p-5 z-50 transition-transform transform-gpu duration-300 ease-in-out">
              <div className="flex flex-col gap-6">
                <NavItem
                  icon={HiUser}
                  text="Website Development"
                  link="/websitedevelopment"
                  onClick={closeMenu}
                />
                <NavItem
                  icon={HiOutlineArrowTopRightOnSquare}
                  text="Ethical-Hacking"
                  link="/eccouncil"
                  onClick={closeMenu}
                />
                <NavItem
                  icon={HiMiniPhone}
                  text="Mobile Application Development"
                  link="/appdevelopment"
                  onClick={closeMenu}
                />
                <NavItem
                  icon={HiOutlineArrowTopRightOnSquare}
                  text="Software Development"
                  link="/websitedevelopment"
                  onClick={closeMenu}
                />
                <NavItem
                  icon={HiOutlineArrowTopRightOnSquare}
                  text="IT-Consultancy"
                  link="/internship"
                  onClick={closeMenu}
                />
              </div>
            </div>
          </div>
          <div className="relative group">
            <div className="flex items-center space-x-2 hover:text-blue-600 cursor-pointer">
              <GrServices />
              <span>Partners</span>
            </div>
            <div className="absolute left-0 hidden group-hover:block bg-white shadow-2xl rounded-xl p-5 z-50 transition-transform transform-gpu duration-300 ease-in-out">
              <div className="flex flex-col gap-6">
                <NavItem
                  icon={HiUser}
                  text="Amazon"
                  link="/amazon"
                  onClick={closeMenu}
                />
                <NavItem
                  icon={HiOutlineArrowTopRightOnSquare}
                  text="EC-Council"
                  link="/eccouncil"
                  onClick={closeMenu}
                />
                <NavItem
                  icon={HiMiniPhone}
                  text="NTTDATA Payment services"
                  link="/paymentservice"
                  onClick={closeMenu}
                />
              </div>
            </div>
          </div>
          <NavItem
            icon={SiOpenproject}
            text="Internship"
            link="/internship"
            onClick={closeMenu}
          />
        </div>
        {isOpen && (
          <div className="fixed inset-0 flex flex-col items-center justify-center bg-black text-white space-y-5 z-40 md:hidden">
            <button
              onClick={toggleMenu}
              className="absolute top-5 right-5 text-4xl focus:outline-none "
            >
              <HiX />
            </button>
            <NavItem icon={HiHome} text="Home" link="/" onClick={closeMenu} />
            <NavItem
              icon={HiUser}
              text="About Us"
              link="/aboutus"
              onClick={closeMenu}
            />
            <NavItem
              icon={HiOutlineArrowTopRightOnSquare}
              text="Career"
              link="/career"
              onClick={closeMenu}
            />
            <NavItem
              icon={HiMiniPhone}
              text="Contact Us"
              link="/contactus"
              onClick={closeMenu}
            />
            <NavItem
              icon={SiOpenproject}
              text="Internship"
              link="/internship"
              onClick={closeMenu}
            />
          </div>
        )}
      </nav>

      {/* Sticky Navbar */}
      {isSticky && (
        <nav className="bg-white w-full fixed top-0 left-0 flex items-center justify-between py-2 px-4 shadow-lg z-50">
          <NavLink to="/" className="flex items-center ml-5">
            <img
              src={digilogo}
              alt="logo"
              className="w-12 transition-transform transform-gpu hover:blur-0 mr-2"
            />
            <div className="flex flex-col">
              <span className="text-lg font-bold text-blue-950">
                DigiSoulTech
              </span>
              <span className="text-xs">Complete IT Solution</span>
            </div>
          </NavLink>
          <div className="flex items-center space-x-5 mr-40">
            <NavItem icon={HiHome} text="Home" link="/" onClick={closeMenu} />
            <NavItem
              icon={HiUser}
              text="About Us"
              link="/aboutus"
              onClick={closeMenu}
            />
            <NavItem
              icon={HiOutlineArrowTopRightOnSquare}
              text="Career"
              link="/career"
              onClick={closeMenu}
            />
            <NavItem
              icon={HiMiniPhone}
              text="Contact Us"
              link="/contactus"
              onClick={closeMenu}
            />
            <NavItem
              icon={SiOpenproject}
              text="Internship"
              link="/internship"
              onClick={closeMenu}
            />
          </div>
        </nav>
      )}
    </>
  );
};

export default Navbar;
